'use client';

import Input from '@/components/commons/input';
import SubmitButton from '@/components/commons/submit-button';
import { useSignIn } from '@/webservices/hooks/auth/useSignIn';
import Link from 'next/link';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

type FormData = {
	email: string;
	password: string;
};

export default function SignInForm({
	planSearchParam,
	tokenSearchParam,
}: {
	planSearchParam: string | string[] | undefined;
	tokenSearchParam?: string | string[] | undefined;
}) {
	const { signIn, signInTenant } = useSignIn(planSearchParam);
	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm<FormData>();

	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<unknown>(undefined);

	const signInMethod = async (data: FormData) =>
		tokenSearchParam
			? signInTenant(data.email, data.password, tokenSearchParam as string)
			: signIn(data.email, data.password);

	async function submitHandler(data: FormData) {
		setIsLoading(true);

		await signInMethod(data).catch((error: unknown) => {
			setError(error);
			setTimeout(() => {
				setError(undefined);
				setIsSubmitted(false);
			}, 2000);
		});

		setIsLoading(false);
		setIsSubmitted(true);
	}

	return (
		<form className="flex flex-col gap-4" onSubmit={handleSubmit(submitHandler)}>
			<Input
				autoComplete="username"
				registered={register('email', { required: true })}
				error={errors.email}
				label="Adresse email"
				type="email"
				floatingLabel
			/>

			<Input
				autoComplete="current-password"
				registered={register('password', {
					required: true,
				})}
				error={errors.password}
				label="Mot de passe"
				type="password"
				floatingLabel
			/>

			<SubmitButton
				isLoading={isLoading}
				isSubmitted={isSubmitted}
				isErrored={!!error}
				text="Me connecter"
			/>

			<Link
				href="/reset-password"
				className="text-center text-black-text underline underline-offset-2"
			>
				Mot de passe oublié&nbsp;?
			</Link>
		</form>
	);
}
