import { trackLogin } from '@/utils/tracking';
import { signInTenantWs, signInWs } from '@/webservices/auth';
import Cookies from 'js-cookie';
import { useRouter } from 'next/navigation';

export const useSignIn = (planSearchParam: string | string[] | undefined) => {
	const router = useRouter();

	const signIn = async (email: string, password: string) => {
		const response = await signInWs({ email, password });
		if (response.access_token) {
			const date = new Date();
			date.setDate(date.getDate() + 7);

			Cookies.set('accessToken', JSON.stringify(response.access_token), {
				expires: date,
				secure:
					process.env.NEXT_PUBLIC_VERCEL_ENV !== 'development' &&
					process.env.NEXT_PUBLIC_VERCEL_ENV !== 'local',
				path: '/',
				sameSite:
					process.env.NEXT_PUBLIC_VERCEL_ENV === 'development' ||
					process.env.NEXT_PUBLIC_VERCEL_ENV === 'local'
						? 'Lax'
						: 'None',
			});

			trackLogin('password');

			router.push(planSearchParam ? `/app?plan=${planSearchParam as string}` : '/app');
		}

		return response;
	};

	const signInTenant = async (email: string, password: string, token: string) => {
		const response = await signInTenantWs({ email, password, token });
		if (response.access_token) {
			const date = new Date();
			date.setDate(date.getDate() + 7);

			Cookies.set('accessToken', JSON.stringify(response.access_token), {
				expires: date,
				secure:
					process.env.NEXT_PUBLIC_VERCEL_ENV !== 'development' &&
					process.env.NEXT_PUBLIC_VERCEL_ENV !== 'local',
				path: '/',
				sameSite:
					process.env.NEXT_PUBLIC_VERCEL_ENV === 'development' ||
					process.env.NEXT_PUBLIC_VERCEL_ENV === 'local'
						? 'Lax'
						: 'None',
			});

			trackLogin('password');

			router.push(planSearchParam ? `/app?plan=${planSearchParam as string}` : '/app');
		}

		return response;
	};

	return { signIn, signInTenant };
};
